<script setup>
import { ref, onMounted, useSSRContext, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"
import Toast from 'primevue/toast'

//import Order from './GartenKultur-Order.vue'

const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()

const orders = ref([])
const newOrderDialog = ref(false)
const users = ref([])
const order = ref({
    name: "",
    date: "",
    source: 1,
    type: 1,
    object_street_nr: "",
    object_postcode: "",
    object_city: "",
    object_type: 1,
    billing_street_nr: "",
    billing_postcode: "",
    billing_city: "",
    billing_po_box: "",
    contacts: [],
    phone_mobile1: "",
    phone_mobile2: "",
    phone_office: "",
    phone_private: "",
    email_main: "",
    email_secondary: "",
    email_billing: "",
    notes: "",
    erp_import: false,
    offers: [],
    invoices: [],
    architect: null,
})
const sources = ref([
    {label: "Telefon", value: 1},
    {label: "E-Mail", value: 2},
    {label: "Web", value: 3},
    {label: "Messe", value: 4},
    {label: "Empfehlung", value: 5},
    {label: "Sonstiges", value: 6}
])
const object_types = ref([
    {label: "Neubau", value: 1},
    {label: "Umbau", value: 2},
    {label: "Renovation", value: 3},
    {label: "Garten", value: 4},
    {label: "Sonstiges", value: 5}
])
const types = ref([
    {label: "Interessent", value: 1},
    {label: "Kunde", value: 2},
    {label: "Lieferant", value: 3},
    {label: "Sonstiges", value: 4}
])
const active_workflow = ref("inbox")
const workflow_tabs = ref([])
const filter_orders = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})

onMounted( async () => {
    get_orders()
    get_users()
    validate_order_form()
})

const get_orders = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/gartenkultur/get-orders')
    .then(response => {
        console.log(response.data)
        orders.value = response.data
        loader.value = false
    })
}

const get_users = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/gartenkultur/get-users')
    .then(response => {
        console.log(response.data)
        users.value = response.data
        loader.value = false
    })
}

const new_order = () => {
    newOrderDialog.value = true
}

const save_new_order = async () => {
    loader.value = true
    let payload = order.value
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/gartenkultur/create-order", payload)
    .then(response => {
        console.log(response.data)
        newOrderDialog.value = false
        toast.add({severity:'success', summary: 'Lead erstellt', detail: 'Der Lead wurde erfolgreich erstellt.', life: 3000});
        loader.value = false
        get_orders()
    })
}

const delete_order = (e) => {
    confirm.require({
        message: 'Bist du sicher, dass du diesen Lead löschen möchtest?',
        header: 'Lead löschen?',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja, löschen',
        acceptClass: 'p-button-wanring',
        rejectLabel: 'Abbrechen',
        accept: () => {
            loader.value = true
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/gartenkultur/delete-order", e)
                .then(response => {
                    purchases.value = response.data
                    toast.add({severity:'success', summary: 'Lead gelöscht', detail: 'Der Lead wurde erfolgreich gelöscht.', life: 3000});
                }).catch(error => {
                console.error("There was an error!", error.message);
            })
            loader.value = false
        },
        reject: () => {
            toast.add({severity:'info', summary: 'Lead nicht gelöscht', detail: 'Der Lead wurde nicht gelöscht.', life: 3000});
        }
    })

}

const open_workflow = (e) => {
    let workflow_id = e.data.id
    for (let key in orders.value){
        if (purchases.value[key].id == workflow_id) {
            let found = workflow_tabs.value.some((el) => el.workflow_id === workflow_id);
            if (!found) {
                let name = ""
                if (purchases.value[key].workflow_id > 25) {
                    name = orders.value[key].name.substring(0, 25) + "..."
                } else {
                    name = orders.value[key].name
                }
                workflow_tabs.value.push({
                    "workflow_id": orders.value[key].id,
                    "name": name
                })
                active_workflow.value = orders.value[key].id
            }
            else
            {
                active_workflow.value = e.data.id
            }
        }
    }
}

const close_tab = (workflow_id) => {
    for (let key in workflow_tabs.value){
        if (workflow_tabs.value[key]["workflow_id"] == workflow_id){
            workflow_tabs.value.splice(key, 1)
            console.log("workflow_tabs", workflow_tabs.value.length)
            if (workflow_tabs.value.length == 0) {
                active_workflow.value = "inbox"
            } else {
                active_workflow.value = workflow_tabs.value[workflow_tabs.value.length - 1]["workflow_id"]
            }
        }
    }
}

const select_architect = (e) => {
    confirm.require({
        message: 'Are you sure you want to delete this purchase?',
        header: 'Delete Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Yes, delete this purchase',
        acceptClass: 'p-button-wanring',
        rejectLabel: 'Cancel',
        accept: () => {
            loader.value = true
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/flyingtiger/delete-purchase", e)
                .then(response => {
                    purchases.value = response.data
                    toast.add({severity:'success', summary: 'Einkauf gelöscht', detail: 'Der Einkauf wurde erfolgreich gelöscht.', life: 3000});
                }).catch(error => {
                console.error("There was an error!", error.message);
            })
            loader.value = false
        },
        reject: () => {
            toast.add({severity:'info', summary: 'Einkauf nicht gelöscht', detail: 'Der Einkauf wurde nicht gelöscht.', life: 3000});
        }
    })
}

const validation_new_order_form = ref({
    main_infos_counter: 0,
    name_valid: true,
    name_message: 'Bitte geben Sie eine Beschreibung ein.',
    date_valid: true,
    date_message: 'Bitte geben Sie ein Datum ein.',
    source_valid: true,
    source_message: 'Bitte wählen Sie eine Quelle aus.',
    type_valid: true,
    type_message: 'Bitte wählen Sie einen Typ aus.',
})
const validate_order_form = () => {
    validation_new_order_form.value.main_infos_counter = 0
    if (!order.value.name) {
        validation_new_order_form.value.name_valid = false
        validation_new_order_form.value.main_infos_counter += 1
    }
    if (!order.value.date) {
        validation_new_order_form.value.date_valid = false
        validation_new_order_form.value.main_infos_counter += 1
    }
    if (!order.value.source) {
        validation_new_order_form.value.source_valid = false
        validation_new_order_form.value.main_infos_counter += 1
    }
    if (!order.value.type) {
        validation_new_order_form.value.type_valid = false
        validation_new_order_form.value.main_infos_counter += 1
    }
}

watch ([order.value], (currentValue, oldValue) => {
    validate_order_form()
    console.log(validation_new_order_form.value)
})

const open_url = (url) => {
    window.open(url, '_blank')
}

const format_swiss_date = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4)
}

</script>
<style>
.tab-primrary {
    background-color: #3751ff;
    color: #FFFFFF;
}
.tab-inactive {
    background-color: #0d2f3b;
    color: #FFFFFF;
}
.tab-hidden {
    display: none;
}
</style>
<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>
<template>
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <Toast />
    <Toolbar>
    <ConfirmDialog />
        <template #start>
            <Button rounded label="Posteingang" icon="pi pi-file-excel" v-tooltip.top="'Posteingang'" @click="toggle_active_workflow('inbox')" :class="{'tab-primary': active_workflow == 'inbox','tab-inactive': active_workflow != 'inbox'}" class="mr-2" />
            <Button rounded v-for="(workflow_tab, key) in workflow_tabs" :key="key" @click="toggle_active_workflow(workflow_tab.workflow_id)" :class="{'tab-primary': active_workflow == 'inbox','tab-inactive': active_workflow != workflow_tab.workflow_id}" class="mr-2"><i class="pi pi-file mr-3"/>{{workflow_tab.name}}<i class="pi pi-times-circle ml-3 text-red-100" @click="close_tab(workflow_tab.workflow_id)" /></Button>
        </template>
    </Toolbar>
    <div v-if="active_workflow == 'inbox'">
        <Toolbar class="mt-2">
            <template #start>
                <span class="p-input-icon-left mr-2">
                    <i class="pi pi-search" />
                    <InputText v-model="filter_orders['global'].value" placeholder="Suche" class="w-full" />
                </span>
                <Button label="Neuer Lead" @click="new_order()" class="w-auto mr-2" icon="pi pi-plus" v-tooltip.right="'Neuer Lead erfassen'" />
            </template>
        </Toolbar>
        <DataTable class="mt-3" v-model:filters="filter_orders" :value="orders" :rows="200" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[200,400,1000]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
            <Column field="date" header="Date" sortable>
                <template #body="slotProps">
                    {{ format_swiss_date(slotProps.data.date) }}
                </template>
            </Column>
            <Column field="name" header="Beschreibung">
                <template #body="slotProps">
                    <span class="cursor-pointer font-bold" @click="open_workflow(slotProps)">{{slotProps.data.name}}</span>
                </template>
            </Column>
            <Column field="type" header="Typ">
                <template #body="slotProps">
                    <Chip v-if="slotProps.data.type == 1" label="Interessent" class="bg-pink-600 text-white" />
                    <Chip v-else-if="slotProps.data.type == 2" label="Kunde" class="bg-green-600 text-white" />
                    <Chip v-else-if="slotProps.data.type == 3" label="Lieferant" />
                    <Chip v-else-if="slotProps.data.type == 4" label="Sonstiges" />
                </template>
            </Column>
            <Column field="source" header="Quelle">
                <template #body="slotProps">
                    <Chip v-if="slotProps.data.source == 1" label="Telefon" class="bg-blue-600 text-white" />
                    <Chip v-else-if="slotProps.data.source == 2" label="E-Mail" class="bg-orange-600 text-white" />
                    <Chip v-else-if="slotProps.data.source == 3" label="Web" class="bg-yellow-600 text-white" />
                    <Chip v-else-if="slotProps.data.source == 4" label="Messe" class="bg-red-600 text-white" />
                    <Chip v-else-if="slotProps.data.source == 5" label="Empfehlung" class="bg-purple-600 text-white" />
                    <Chip v-else-if="slotProps.data.source == 6" label="Sonstiges" class="bg-gray-600 text-white" />
                </template>
            </Column>
            <Column header="Stammdaten">
                <template #body="slotProps">
                    <Button class="p-button-rounded p-button-text p-button-primary" icon="pi pi-pencil" @click="newOrderDialog = true" badge="6" badgeSeverity="danger" />
                    <Button class="p-button-rounded p-button-primary" icon="pi pi-send" @click="open_workflow(slotProps)" />
                </template>
            </Column>
            <Column header="ERP">
                <template #body="slotProps">
                    <Avatar icon="pi pi-check" class="ml-2 bg-green-600 text-white" @click="open_workflow(slotProps)" shape="circle" />
                </template>
            </Column>
            <Column header="Offerten">
                <template #body="slotProps">
                    <Button class="p-button-rounded p-button-text p-button-primary" icon="pi pi-file" @click="open_url(slotProps)"  badge="2" badgeSeverity="info" />
                    <Button class="p-button-rounded p-button-text p-button-success" icon="pi pi-plus" @click="open_url(slotProps)" />
                </template>
            </Column>
            <Column header="Fotos">
                <template #body="slotProps">
                    <Button class="p-button-rounded p-button-text p-button-primary" icon="pi pi-image" @click="open_url(slotProps)"  badge="14" badgeSeverity="info" />
                    <Button class="p-button-rounded p-button-text p-button-success" icon="pi pi-plus" @click="open_url(slotProps)" />
                </template>
            </Column>
            <Column header="Plangrundlagen">
                <template #body="slotProps">
                    <Button class="p-button-rounded p-button-text p-button-primary" icon="pi pi-map-marker" @click="open_url(slotProps)"  badge="1" badgeSeverity="info" />
                    <Button class="p-button-rounded p-button-text p-button-success" icon="pi pi-plus" @click="open_url(slotProps)" />
                </template>
            </Column>
            <Column field="architect" header="Architekt">
                <template #body="slotProps">
                    <Dropdown placeholder="Architekt auswählen" @change="select_architect" :options="users" optionLabel="name" optionValue="id" v-model="slotProps.data.architect" class="w-full" />
                </template>
            </Column>
            <Column>
                <template #body="slotProps">
                    <Button class="p-button-rounded p-button-text p-button-danger" icon="pi pi-trash" @click="delete_order(slotProps.data)" />
                </template>
            </Column>
        </DataTable>
    </div>
    <div v-for="(workflow_tab, key) in workflow_tabs" :key="key" :hidden="active_workflow != workflow_tab.workflow_id">
        <!--<Purchase :workflow_id="workflow_tab.workflow_id" />-->
    </div>
    <Dialog v-model:visible="newOrderDialog" :style="{width: '1200px'}" header="Neuer Lead" :modal="true" class="p-fluid z-5">
        <Accordion :activeIndex="0" expandIcon="pi pi-plus" collapseIcon="pi pi-minus">
            <AccordionTab>
                <template #header>
                    <span class="flex align-items-center gap-2 w-full">
                        <span class="font-bold white-space-nowrap">Hauptinfos</span>
                        <Badge v-if="validation_new_order_form.main_infos_counter > 0" :value="validation_new_order_form.main_infos_counter" class="ml-auto mr-2 bg-red-600" />
                        <Avatar v-else icon="pi pi-check" class="ml-auto mr-2 bg-green-600 text-white" shape="circle" />
                    </span>
                </template>
                <div class="col-12 formgrid grid">
                    <div class="field col-6 mt-3">
                        <span class="p-float-label">
                            <InputText id="name" type="text" v-model="order.name" />
                            <label for="name">Beschreibung</label>
                        </span>
                    </div>
                    <div class="field col-6 mt-3">
                        <span class="p-float-label">
                            <Calendar id="date" showIcon v-model="order.date" dateFormat="dd.mm.yy" />
                            <label for="date">Datum</label>
                        </span>
                    </div>
                    <div class="field col-6 mt-3">
                        <span class="p-float-label">
                            <Dropdown id="source" v-model="order.source" :options="sources" optionLabel="label" optionValue="value" />
                            <label for="source">Quelle</label>
                        </span>
                    </div>
                    <div class="field col-6 mt-3">
                        <span class="p-float-label">
                            <Dropdown id="type" v-model="order.type" :options="types" optionLabel="label" optionValue="value" />
                            <label for="type">Typ</label>
                        </span>
                    </div>
                </div>
            </AccordionTab>
            <AccordionTab>
                <template #header>
                    <span class="flex align-items-center gap-2 w-full">
                        <span class="font-bold white-space-nowrap">Objekt</span>
                        <Badge value="3" class="ml-auto mr-2 bg-red-600" />
                    </span>
                </template>
                <div class="col-12 formgrid grid">
                    <div class="field col-6 mt-3">
                        <span class="p-float-label">
                            <InputText id="object_street_nr" type="text" v-model="order.object_street_nr" />
                            <label for="object_street_nr">Objekt Strasse/Nr.</label>
                        </span>
                    </div>
                    <div class="field col-6 mt-3">
                        <span class="p-float-label">
                            <InputText id="object_postcode" type="text" v-model="order.object_postcode" />
                            <label for="object_postcode">Objekt PLZ</label>
                        </span>
                    </div>
                    <div class="field col-6 mt-3">
                        <span class="p-float-label">
                            <InputText id="object_city" type="text" v-model="order.object_city" />
                            <label for="object_city">Objekt Ort</label>
                        </span>
                    </div>
                    <div class="field col-6 mt-3">
                        <span class="p-float-label">
                            <Dropdown id="object_type" v-model="order.object_type" :options="object_types" optionLabel="label" optionValue="value" />
                            <label for="object_type">Objekt Typ</label>
                        </span>
                    </div>
                </div>
            </AccordionTab>
            <AccordionTab>
                <template #header>
                    <span class="flex align-items-center gap-2 w-full">
                        <span class="font-bold white-space-nowrap">Rechnungsadresse</span>
                        <Badge value="3" class="ml-auto mr-2 bg-red-600" />
                    </span>
                </template>
                <div class="col-12 formgrid grid">
                    <div class="field col-6 mt-3">
                        <span class="p-float-label">
                            <InputText id="billing_street_nr" type="text" v-model="order.billing_street_nr" />
                            <label for="billing_street_nr">Rechnungsadresse Strasse/Nr.</label>
                        </span>
                    </div>
                    <div class="field col-6 mt-3">
                        <span class="p-float-label">
                            <InputText id="billing_postcode" type="text" v-model="order.billing_postcode" />
                            <label for="billing_postcode">Rechnungsadresse PLZ</label>
                        </span>
                    </div>
                    <div class="field col-6 mt-3">
                        <span class="p-float-label">
                            <InputText id="billing_city" type="text" v-model="order.billing_city" />
                            <label for="billing_city">Rechnungsadresse Ort</label>
                        </span>
                    </div>
                    <div class="field col-6 mt-3">
                        <span class="p-float-label">
                            <InputText id="billing_po_box" type="text" v-model="order.billing_po_box" />
                            <label for="billing_po_box">Rechnungsadresse Postfach</label>
                        </span>
                    </div>
                </div>
            </AccordionTab>
            <AccordionTab>
                <template #header>
                    <span class="flex align-items-center gap-2 w-full">
                        <span class="font-bold white-space-nowrap">Telefon</span>
                        <Badge value="1" class="ml-auto mr-2 bg-red-600" />
                    </span>
                </template>
                <div class="col-12 formgrid grid">
                    <div class="field col-6 mt-3">
                        <span class="p-float-label">
                            <InputText id="phone_mobile1" type="text" v-model="order.phone_mobile1" />
                            <label for="phone_mobile1">Mobile 1</label>
                        </span>
                    </div>
                    <div class="field col-6 mt-3">
                        <span class="p-float-label">
                            <InputText id="phone_mobile2" type="text" v-model="order.phone_mobile2" />
                            <label for="phone_mobile2">Mobile 2</label>
                        </span>
                    </div>
                    <div class="field col-6 mt-3">
                        <span class="p-float-label">
                            <InputText id="phone_office" type="text" v-model="order.phone_office" />
                            <label for="phone_office">Büro</label>
                        </span>
                    </div>
                    <div class="field col-6 mt-3">
                        <span class="p-float-label">
                            <InputText id="phone_private" type="text" v-model="order.phone_private" />
                            <label for="phone_private">Privat</label>
                        </span>
                    </div>
                </div>
            </AccordionTab>
            <AccordionTab>
                <template #header>
                    <span class="flex align-items-center gap-2 w-full">
                        <span class="font-bold white-space-nowrap">E-Mail</span>
                        <Badge value="1" class="ml-auto mr-2 bg-red-600" />
                    </span>
                </template>
                <div class="col-12 formgrid grid">
                    <div class="field col-6 mt-3">
                        <span class="p-float-label">
                            <InputText id="email_main" type="text" v-model="order.email_main" />
                            <label for="email_main">E-Mail Hauptadresse</label>
                        </span>
                    </div>
                    <div class="field col-6 mt-3">
                        <span class="p-float-label">
                            <InputText id="email_secondary" type="text" v-model="order.email_secondary" />
                            <label for="email_secondary">E-Mail Sekundäradresse</label>
                        </span>
                    </div>
                    <div class="field col-6 mt-3">
                        <span class="p-float-label">
                            <InputText id="email_billing" type="text" v-model="order.email_billing" />
                            <label for="email_billing">E-Mail Rechnungsadresse</label>
                        </span>
                    </div>
                </div>
            </AccordionTab>
            <AccordionTab>
                <template #header>
                    <span class="flex align-items-center gap-2 w-full">
                        <span class="font-bold white-space-nowrap">Notizen</span>
                    </span>
                </template>
                <div class="col-12 formgrid grid">
                    <div class="field col-12 mt-3">
                        <span class="p-float-label">
                            <Textarea id="notes" type="text" v-model="order.notes" />
                            <label for="notes">Notizen</label>
                        </span>
                    </div>
                </div>
            </AccordionTab>
        </Accordion>
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-3">
                <Message v-if="validation_new_order_form.main_infos_counter > 0" severity="error">Bitte alle Pflichtfelder ausfüllen</Message>
                <Button :disabled="validation_new_order_form.main_infos_counter > 0" label="Lead erfassen" @click="save_new_order()" class="w-auto mr-2 mb-1" icon="pi pi-check" />
                <Button label="Abbrechen" @click="newOrderDialog = false" class="w-auto mb-1 bg-red-600" icon="pi pi-times" />
            </div>
        </div>
    </Dialog>
</template>