<script setup>
import { ref, onMounted, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import { useConfirm } from "primevue/useconfirm"
import { FilterMatchMode } from 'primevue/api'
import axios from 'axios'

/////////////////////
// Interface Props //
/////////////////////
const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()

// filters
const filter_menu = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
const changes = ref(0)
const subchanges = ref(0)

//////////////////
// Data Sources //
//////////////////

const menu = ref([])
const expanded_submenus = ref([])
const edit_submenu_dialog = ref(false)
const edit_page_standard_dialog = ref(false)
const edit_page_team_dialog = ref(false)
const edit_page_references_dialog = ref(false)
const submenu = ref([])
const menu_index = ref()
const menu_parent = ref()
const page = ref({})
const page_template = ref('static')
const page_slug = ref()
const templates = ref([
    {name: 'Statisch', value: 'static'},
    {name: 'Standard', value: 'standard'},
    {name: 'Referenzen', value: 'references'},
    {name: 'Team', value: 'team'}
])

onMounted(() => {
    get_menu()
})

const get_menu = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/gartenkultur/website/get-menu')
    .then(response => {
        menu.value = response.data
        loader.value = false
        changes.value = 0
    })
}

const edit_menu = (e) => {
    console.log(e)
    menu.value[e.index] = e.newData
    changes.value++
    toast.add({severity: 'success', summary: 'Menüpunkt geändert', life: 3000})
}

const menu_reorder = (e) => {
    menu.value = e.value
    changes.value++
    toast.add({severity: 'success', summary: 'Reihenfolge geändert', life: 3000})
}

const edit_submenus = (e) => {
    console.log(e)
    submenu.value = e.data.submenu
    menu_index.value = e.index
    menu_parent.value = e.data.parent
    edit_submenu_dialog.value = true
}

const edit_submenu = (e) => {
    submenu.value[e.index] = e.newData
    subchanges.value++
    toast.add({severity: 'success', summary: 'Untermenüpunkt geändert', life: 3000})
}

const submenu_reorder = (e) => {
    submenu.value = e.value
    subchanges.value++
    toast.add({severity: 'success', summary: 'Reihenfolge geändert', life: 3000})
}

const delete_menu = (id) => {
    confirm.require({
        message: 'Möchten Sie diesen Menüpunkt wirklich löschen?',
        header: 'Bestätigung',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja, löschen!',
        rejectLabel: 'Nein',
        accept: () => {
            menu.value.splice(id, 1)
            changes.value++
            toast.add({severity: 'success', summary: 'Menüpunkt gelöscht', life: 3000})
        }
    })
}

const delete_submenu = (id) => {
    confirm.require({
        message: 'Möchten Sie diesen Untermenüpunkt wirklich löschen?',
        header: 'Bestätigung',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja, löschen!',
        rejectLabel: 'Nein',
        accept: () => {
            submenu.value.splice(id, 1)
            subchanges.value++
            toast.add({severity: 'success', summary: 'Untermenüpunkt gelöscht', life: 3000})
        }
    })
}

const save_menu = () => {
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/gartenkultur/website/save-menu', menu.value)
    .then(response => {
        changes.value = 0
        toast.add({severity: 'success', summary: 'Menü gespeichert', life: 3000})
    })
}

const save_submenu = () => {
    menu.value[menu_index.value].submenu = submenu.value
    subchanges.value = 0
    changes.value++

    toast.add({severity: 'success', summary: 'Untermenü gespeichert', life: 3000})
}

const add_menu = () => {
    menu.value = [...menu.value, {title: '', slug: '', template: 'static', submenu: []}]
    changes.value++
}

const add_submenu = (data) => {
    console.log(data)
    submenu.value = [...submenu.value, {title: '', slug: '', template: 'static', parent: menu_parent.value, icon: '', card: ''}]
    subchanges.value++
}

const edit_page = (data) => {
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/gartenkultur/website/edit-page', {slug: data.slug})
    .then(response => {
        page.value = response.data
        loader.value = false
        if (data.template == 'standard') {
            page_slug.value = data.slug
            page_template.value = data.template
            edit_page_standard_dialog.value = true
        }
        if (data.template == 'team') {
            page_slug.value = data.slug
            page_template.value = data.template
            edit_page_team_dialog.value = true
        }
        if (data.template == 'references') {
            page_slug.value = data.slug
            page_template.value = data.template
            edit_page_references_dialog.value = true
        }
    })
}

const save_page = () => {
    page.value.slug = page_slug.value
    page.value.template = page_template.value
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/gartenkultur/website/save-page', page.value)
    .then(response => {
        toast.add({severity: 'success', summary: 'Seite gespeichert', life: 3000})
    })
}

const add_page_slider = () => {
    if (page.value.slider == null) {
        page.value.slider = []
    }
    page.value.slider = [...page.value.slider, {image: ''}]
}

const page_slider_reorder = (e) => {
    page.value.slider = e.value
    toast.add({severity: 'success', summary: 'Reihenfolge geändert', life: 3000})
}

const delete_page_slider = (data) => {
    page.value.slider.splice(data.index, 1)
    toast.add({severity: 'success', summary: 'Bild gelöscht', life: 3000})
}

const add_page_team = () => {
    if (page.value.team == null) {
        page.value.team = []
    }
    page.value.team = [...page.value.team, {image: '', name: '', role: '', email: ''}]
}

const edit_page_team = (e) => {
    page.value.team[e.index] = e.newData
    toast.add({severity: 'success', summary: 'Teammitglied geändert', life: 3000})
}

const page_team_reorder = (e) => {
    page.value.team = e.value
    toast.add({severity: 'success', summary: 'Reihenfolge geändert', life: 3000})
}

const delete_page_team = (data) => {
    page.value.team.splice(data.index, 1)
    toast.add({severity: 'success', summary: 'Teammitglied gelöscht', life: 3000})
}

const add_page_reference = () => {
    if (page.value.references == null) {
        page.value.references = []
    }
    page.value.references = [...page.value.references, {image: '', title: '', slug: ''}]
}

const edit_page_reference = (e) => {
    page.value.references[e.index] = e.newData
    toast.add({severity: 'success', summary: 'Referenz geändert', life: 3000})
}

const page_reference_reorder = (e) => {
    page.value.references = e.value
    toast.add({severity: 'success', summary: 'Reihenfolge geändert', life: 3000})
}

const file_upload = (e, filename, path, post, index) => {
    let reader = new FileReader()
    let file = null
    reader.readAsDataURL(e.files[0])
    reader.onload = () => {
        file = reader.result
        let payload = {
            "file": file,
            "filename": filename,
            "path": path
        }
        axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/gartenkultur/website/upload-file", payload)
            .then(response => {
                if (post == 'submenu_icon') {
                    submenu.value[index].icon = response.data.url
                    subchanges.value++
                }
                if (post == 'submenu_card') {
                    submenu.value[index].card = response.data.url
                    subchanges.value++
                }
                if (post == 'standard_page_banner') {
                    page.value.banner = response.data.url
                }
                if (post == 'block_1_image') {
                    page.value.block_1_image = response.data.url
                }
                if (post == 'block_2_image') {
                    page.value.block_2_image = response.data.url
                }
                if (post == 'standard_slider') {
                    page.value.slider[index].image = response.data.url
                }
                if (post == 'team_image') {
                    page.value.team[index].image = response.data.url
                }
                if (post == 'reference_image') {
                    page.value.references[index].image = response.data.url
                }
            }).catch(error => {
            console.error("There was an error!", error.message);
        })
    }
}

const index_vs_length = (index, slug) => {
    let checker = false
    menu.value.forEach(element => {
        element.forEach(sub_element => {
            if (sub_element.slug == slug) {
                if (index < element.length - 1) {
                    checker = true
                }
            }
        })
    })
    return checker
}

</script>
<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>
<template>
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <div class="overflow-hidden rounded mt-4 w-full p-2 flex align-items-start" style="border: 1px solid #e2e8f0; background: #f8fafc">
        <Button icon="pi pi-refresh" @click="get_menu()" class="mr-2 w-auto w-3rem" />
        <Button :disabled="changes == 0 ? true : false" label="Speichern" icon="pi pi-save" class="mr-2 w-auto p-button-success" :badge="String(changes)" badgeSeverity="danger" @click="save_menu()" />
        <Button label="Menu" icon="pi pi-plus" class="mr-2 w-auto" @click="add_menu()" />
    </div>
    <div rounded class="overflow-hidden shadow-4 rounded border-200 mt-4">
        <DataTable v-model:filters="filter_menu" v-model:expandedRows="expanded_submenus" :value="menu" responsiveLayout="scroll" editMode="cell" @cell-edit-complete="edit_menu" :rowHover="true" @rowReorder="menu_reorder">
            <Column expander style="width: 4rem" />
            <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
            <Column field="title" header="Titel" style="">
                <template #editor="{ data, field }">
                    <InputText v-model="data[field]" />
                </template>
                <template #body="{ data, field }">
                    <span class="font-bold">{{data[field]}}</span>
                    <Badge v-if="data.submenu.length > 0" :value="String(data.submenu.length)" severity="info" class="ml-2" />
                </template>
            </Column>
            <Column field="slug" header="URL" style="">
                <template #editor="{ data, field }">
                    <InputText v-model="data[field]" />
                </template>
                <template #body="{ data, field }">
                    {{data[field]}}
                </template>
            </Column>
            <Column field="template" header="Template" style="">
                <template #editor="{ data, field }">
                    <Dropdown v-model="data[field]" :options="templates" optionLabel="name" optionValue="value" />
                </template>
                <template #body="{ data, field }">
                    {{data[field]}}
                </template>
            </Column>
            <Column>
                <template #body="slotProps">
                    <Button :disabled="slotProps.data.submenu.length == 0 ? true : false" icon="pi pi-table" class="mr-2 p-button-rounded p-button-text" @click="edit_submenus(slotProps)" v-tooltip.top="'Untermenu editieren'" />
                    <Button v-if="slotProps.data.template != 'static'" icon="pi pi-pencil" class="mr-2 p-button-rounded p-button-text" @click="edit_page(slotProps.data)" v-tooltip.top="'Inhalt editieren'" />
                    <Button icon="pi pi-trash" class="mr-2 -button-rounded p-button-text p-button-danger" @click="delete_menu(slotProps.index)" v-tooltip.top="'Menu löschen'" />
                </template>
            </Column>
            <template #expansion="slotProps">
                <div rounded class="overflow-hidden shadow-3 border-200">
                    <DataTable :value="slotProps.data.submenu" responsiveLayout="scroll" editMode="cell" :rowHover="true">
                        <Column style="width: 100px">
                            <template #body="slotProps">
                                <Image preview :src="slotProps.data.icon" class="w-3rem h-3rem shadow-2" v-tooltip.right="{ value: '<img src=\'' + slotProps.data.icon + '\' />', escape: false}" />
                            </template>
                        </Column>
                        <Column field="title" header="Submenu-Titel" style="">
                            <template #body="slotProps">
                                <span class="font-bold">{{slotProps.data.title}}</span>
                            </template>
                        </Column>
                        <Column field="slug" header="URL" style="">
                            <template #body="slotProps">
                                {{slotProps.data.slug}}
                            </template>
                        </Column>
                        <Column field="template" header="Template" style="">
                            <template #body="slotProps">
                                {{slotProps.data.template}}
                            </template>
                        </Column>
                        <Column field="parent" hidden />
                        <Column>
                            <template #body="slotProps">
                                <Button icon="pi pi-pencil" class="mr-2 p-button-rounded p-button-text" @click="edit_page(slotProps.data)" v-tooltip.top="'Inhalt editieren'" />
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </template>
        </DataTable>
    </div>
    <Dialog v-model:visible="edit_submenu_dialog" :style="{width: '80%'}" header="Untermenu editieren" :modal="true" class="p-fluid z-5">
        <DataTable :value="submenu" responsiveLayout="scroll" editMode="cell" @cell-edit-complete="edit_submenu" :rowHover="true" @rowReorder="submenu_reorder">
            <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
            <Column header="Icon" style="width: 200px">
                <template #body="slotProps">
                    <Image preview :src="slotProps.data.icon" class="w-full shadow-2 mr-2" v-tooltip.right="{ value: '<img src=\'' + slotProps.data.icon + '\' />', escape: false}" />
                    <FileUpload mode="basic" name="file[]" url="/" accept="image/*" :maxFileSize="10000000" :auto="true" chooseLabel="Hochladen" customUpload @uploader="file_upload($event, slotProps.data.slug + '_icon.webp', 'website/images/', 'submenu_icon', slotProps.index)" />
                </template>
            </Column>
            <Column header="Card" style="width: 200px">
                <template #body="slotProps">
                    <Image preview :src="slotProps.data.card" class="w-full shadow-2 mr-2" v-tooltip.right="{ value: '<img src=\'' + slotProps.data.card + '\' />', escape: false}" />
                    <FileUpload mode="basic" name="file[]" url="/" accept="image/*" :maxFileSize="10000000" :auto="true" chooseLabel="Hochladen" customUpload @uploader="file_upload($event, slotProps.data.slug + '_card.webp', 'website/images/', 'submenu_card', slotProps.index)" />
                </template>
            </Column>
            <Column field="title" header="Titel" style="">
                <template #editor="{ data, field }">
                    <InputText v-model="data[field]" />
                </template>
                <template #body="{ data, field }">
                    <span class="font-bold">{{data[field]}}</span>
                </template>
            </Column>
            <Column field="slug" header="URL" style="">
                <template #editor="{ data, field }">
                    <InputText v-model="data[field]" />
                </template>
                <template #body="{ data, field }">
                    {{data[field]}}
                </template>
            </Column>
            <Column field="template" header="Template" style="">
                <template #editor="{ data, field }">
                    <Dropdown v-model="data[field]" :options="templates" optionLabel="name" optionValue="value" />
                </template>
                <template #body="{ data, field }">
                    {{data[field]}}
                </template>
            </Column>
            <Column field="parent" hidden />
            <Column>
                <template #body="slotProps">
                    <Button icon="pi pi-trash" class="mr-2 -button-rounded p-button-text p-button-danger" @click="delete_submenu(slotProps.index)" v-tooltip.top="'Menu löschen'" />
                </template>
                <template #header="slotProps">
                    <Button icon="pi pi-plus" class="mr-2 -button-rounded p-button-text" @click="add_submenu(slotProps)" v-tooltip.top="'Submenu hinzufügen'" />
                </template>
            </Column>
        </DataTable>
        <div class="flex justify-end mt-4">
            <Button :disabled="subchanges == 0 ? true : false" label="Speichern" icon="pi pi-save" class="w-auto p-button-success mr-2" @click="save_submenu()" :badge="String(subchanges)" badgeSeverity="danger" />
            <Button label="Abbrechen" class="w-auto p-button-danger" @click="edit_submenu_dialog = false, submenu = null, subchanges = 0" />
        </div>
    </Dialog>
    <!-- EDITOR STANDARD -->
    <Dialog v-model:visible="edit_page_standard_dialog" :style="{width: '80%'}" header="Seite editieren" :modal="true" class="p-fluid z-5">
        <div class="col-12 formgrid grid">
            <div class="field col-12">
                <Image preview :src="page.banner" class="w-full shadow-2" />
                <FileUpload mode="basic" name="file[]" url="/" accept="image/*" :maxFileSize="10000000" :auto="true" chooseLabel="Banner Bild" customUpload @uploader="file_upload($event, page_slug + '_banner.webp', 'website/images/', 'standard_page_banner', 0)" />
            </div>
            <div class="field col-12">
                <span class="p-float-label">
                    <InputText id="main_title" v-model="page.main_title" class="mt-3 w-full" />
                    <label for="main_title">Haupt-Titel</label>
                </span>
            </div>
            <div class="field col-12"><span class="font-bold text-xl">Block 1</span></div>
            <div class="field col-6">
                <span class="p-float-label">
                    <InputText id="title" v-model="page.block_1_subtitle" class="mt-3 w-full" />
                    <label for="title">Untertitel</label>
                </span>
                <span class="p-float-label">
                    <InputText id="title" v-model="page.block_1_title" class="mt-3 w-full" />
                    <label for="title">Titel</label>
                </span>
                <Editor v-model="page.block_1_text" class="mt-3 w-full">
                    <template v-slot:toolbar>
                        <span class="ql-formats">
                            <button class="ql-bold" v-tooltip.bottom="'Bold'"></button>
                            <button class="ql-italic" v-tooltip.bottom="'Italic'"></button>
                            <button class="ql-underline" v-tooltip.bottom="'Underline'"></button>
                            <button class="ql-link" v-tooltip.bottom="'Hyperlink'"></button>
                            <button class="ql-clean" v-tooltip.bottom="'Clear Format'"></button>
                            <button class="ql-image" v-tooltip.bottom="'Bild hochladen'"></button>
                        </span>
                    </template>
                </Editor>
            </div>
            <div class="field col-6">
                <Image preview :src="page.block_1_image" class="w-full shadow-2" />
                <FileUpload mode="basic" name="file[]" url="/" accept="image/*" :maxFileSize="10000000" :auto="true" chooseLabel="Block 1 Bild" customUpload @uploader="file_upload($event, page_slug + '_block_1_image.webp', 'website/images/', 'block_1_image', 0)" />
            </div>
            <div class="field col-12"><span class="font-bold text-xl">Slider</span></div>
            <div class="field col-12">
                <DataTable :value="page.slider" class="w-full" @rowReorder="page_slider_reorder">
                    <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
                    <Column field="image" header="Bild" style="width: 40%">
                        <template #body="slotProps">
                            <Image preview :src="slotProps.data.image" class="w-full shadow-2" />
                        </template>
                    </Column>
                    <Column>
                        <template #body="slotProps">
                            <FileUpload mode="basic" name="file[]" url="/" accept="image/*" :maxFileSize="10000000" :auto="true" chooseLabel="Slider Bild" customUpload @uploader="file_upload($event, page_slug + '_slider_'+String(slotProps.index)+'.webp', 'website/images/', 'standard_slider', slotProps.index)" />
                        </template>
                    </Column>
                    <Column>
                        <template #body="slotProps">
                            <Button icon="pi pi-trash" class="mr-2 p-button-rounded p-button-text p-button-danger" v-tooltip.top="'Bild löschen'" @click="delete_page_slider(slotProps)" />
                        </template>
                        <template #header="slotProps">
                            <Button icon="pi pi-plus" class="mr-2 p-button-rounded p-button-text" v-tooltip.top="'Bild hinzufügen'" @click="add_page_slider(slotProps)" />
                        </template>
                    </Column>
                </DataTable>
            </div>
            <div class="field col-12"><span class="font-bold text-xl">Block 2</span></div>
            <div class="field col-6">
                <Image preview :src="page.block_2_image" class="w-full shadow-2" />
                <FileUpload mode="basic" name="file[]" url="/" accept="image/*" :maxFileSize="10000000" :auto="true" chooseLabel="Block 2 Bild" customUpload @uploader="file_upload($event, page_slug + '_block_2_image.webp', 'website/images/', 'block_2_image', 0)" />
            </div>
            <div class="field col-6">
                <span class="p-float-label">
                    <InputText id="title" v-model="page.block_2_subtitle" class="mt-3 w-full" />
                    <label for="title">Untertitel</label>
                </span>
                <span class="p-float-label">
                    <InputText id="title" v-model="page.block_2_title" class="mt-3 w-full" />
                    <label for="title">Titel</label>
                </span>
                <Editor v-model="page.block_2_text" class="mt-3 w-full">
                    <template v-slot:toolbar>
                        <span class="ql-formats">
                            <button class="ql-bold" v-tooltip.bottom="'Bold'"></button>
                            <button class="ql-italic" v-tooltip.bottom="'Italic'"></button>
                            <button class="ql-underline" v-tooltip.bottom="'Underline'"></button>
                            <button class="ql-link" v-tooltip.bottom="'Hyperlink'"></button>
                            <button class="ql-clean" v-tooltip.bottom="'Clear Format'"></button>
                            <button class="ql-image" v-tooltip.bottom="'Bild hochladen'"></button>
                        </span>
                    </template>
                </Editor>
            </div>
            <div class="field col-12">
                <Button label="Speichern" icon="pi pi-save" class="w-auto p-button-success mr-2" @click="save_page(), edit_page_standard_dialog = false" />
                <Button label="Abbrechen" class="w-auto p-button-danger" @click="edit_page_standard_dialog = false, page = null" />
            </div>
        </div>
    </Dialog>
    <!-- TEAM STANDARD -->
    <Dialog v-model:visible="edit_page_team_dialog" :style="{width: '80%'}" header="Team editieren" :modal="true" class="p-fluid z-5">
        <div class="col-12 formgrid grid">
            <div class="field col-12">
                <DataTable :value="page.team" class="w-full" editMode="cell" @cell-edit-complete="edit_page_team" @rowReorder="page_team_reorder">
                    <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
                    <Column field="image" header="Bild" style="width: 20%">
                        <template #body="slotProps">
                            <Image preview :src="slotProps.data.image" class="w-full shadow-2" />
                            <FileUpload mode="basic" name="file[]" url="/" accept="image/*" :maxFileSize="10000000" :auto="true" chooseLabel="Team Bild" customUpload @uploader="file_upload($event, page_slug + '_team_'+String(slotProps.index)+'.webp', 'website/images/', 'team_image', slotProps.index)" />
                        </template>
                    </Column>
                    <Column field="name" header="Name" style="">
                        <template #editor="{ data, field }">
                            <InputText v-model="data[field]" />
                        </template>
                        <template #body="{ data, field }">
                            <span class="font-bold">{{data[field]}}</span>
                        </template>
                    </Column>
                    <Column field="role" header="Rolle" style="">
                        <template #editor="{ data, field }">
                            <InputText v-model="data[field]" />
                        </template>
                        <template #body="{ data, field }">
                            <span class="font-bold">{{data[field]}}</span>
                        </template>
                    </Column>
                    <Column field="email" header="E-Mail" style="">
                        <template #editor="{ data, field }">
                            <InputText v-model="data[field]" />
                        </template>
                        <template #body="{ data, field }">
                            <span class="font-bold">{{data[field]}}</span>
                        </template>
                    </Column>
                    <Column>
                        <template #body="slotProps">
                            <Button icon="pi pi-trash" class="mr-2 p-button-rounded p-button-text p-button-danger" v-tooltip.top="'Team löschen'" @click="delete_page_team(slotProps)" />
                        </template>
                        <template #header="slotProps">
                            <Button icon="pi pi-plus" class="mr-2 p-button-rounded p-button-text" v-tooltip.top="'Team hinzufügen'" @click="add_page_team(slotProps)" />
                        </template>
                    </Column>
                </DataTable>
            </div>
            <div class="field col-12">
                <Button label="Speichern" icon="pi pi-save" class="w-auto p-button-success mr-2" @click="save_page(), edit_page_team_dialog = false" />
                <Button label="Abbrechen" class="w-auto p-button-danger" @click="edit_page_team_dialog = false, page = null" />
            </div>
        </div>
    </Dialog>
    <!-- TEAM STANDARD -->
    <Dialog v-model:visible="edit_page_references_dialog" :style="{width: '80%'}" header="Referenzen editieren" :modal="true" class="p-fluid z-5">
        <div class="col-12 formgrid grid">
            <div class="field col-12">
                <DataTable :value="page.references" class="w-full" editMode="cell" @cell-edit-complete="edit_page_reference" @rowReorder="page_reference_reorder">
                    <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
                    <Column field="image" header="Bild" style="width: 20%">
                        <template #body="slotProps">
                            <Image preview :src="slotProps.data.image" class="w-full shadow-2" />
                            <FileUpload mode="basic" name="file[]" url="/" accept="image/*" :maxFileSize="10000000" :auto="true" chooseLabel="Referenz Bild" customUpload @uploader="file_upload($event, page_slug + '_reference_'+String(slotProps.index)+'.webp', 'website/images/', 'reference_image', slotProps.index)" />
                        </template>
                    </Column>
                    <Column field="title" header="Titel" style="">
                        <template #editor="{ data, field }">
                            <InputText v-model="data[field]" />
                        </template>
                        <template #body="{ data, field }">
                            <span class="font-bold">{{data[field]}}</span>
                        </template>
                    </Column>
                    <Column field="slug" header="URL" style="">
                        <template #editor="{ data, field }">
                            <InputText v-model="data[field]" />
                        </template>
                        <template #body="{ data, field }">
                            <span class="font-bold">{{data[field]}}</span>
                        </template>
                    </Column>
                    <Column>
                        <template #body="slotProps">
                            <Button icon="pi pi-trash" class="mr-2 p-button-rounded p-button-text p-button-danger" v-tooltip.top="'Referenz löschen'" @click="delete_page_reference(slotProps)" />
                        </template>
                        <template #header="slotProps">
                            <Button icon="pi pi-plus" class="mr-2 p-button-rounded p-button-text" v-tooltip.top="'Referenz hinzufügen'" @click="add_page_reference(slotProps)" />
                        </template>
                    </Column>
                </DataTable>
            </div>
            <div class="field col-12">
                <Button label="Speichern" icon="pi pi-save" class="w-auto p-button-success mr-2" @click="save_page(), edit_page_references_dialog = false" />
                <Button label="Abbrechen" class="w-auto p-button-danger" @click="edit_page_references_dialog = false, page = null" />
            </div>
        </div>
    </Dialog>
</template>